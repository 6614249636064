import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';
import { devlog } from 'lib/helpers';


/**
 * SEO.
 */
const propTypes = {
  title:              PropTypes.string,
  //path:               PropTypes.string,
  seo:                PropTypes.shape( {

  } ),
  openImage:          PropTypes.object,
  openTitle:          PropTypes.string,
  openDescription:    PropTypes.string,
  twitterImage:       PropTypes.object,
  twitterTitle:       PropTypes.string,
  twitterDescription: PropTypes.string,
}
const SEO = p => {

  const title = !! p.seo && p.seo.seo_title ? p.seo.seo_title : p.title;
  const description = !! p.seo ? p.seo.meta_description : null;
  const { pathname } = useLocation();
  const url = `${process.env.BASE_URL}${pathname}`;
  const baseTitle = 'Epsilon Asset Management';

  return(

    <Helmet>

      <html lang="en" />
      <meta http-equiv="Accept-CH" content="DPR, Width, Viewport-Width" />
      <meta property="og:locale" content="en_US" />

      { !! p.seo &&
        <meta name="description" content={ p.seo.meta_description } />
      }

      <title>{ `${title} | ${baseTitle}` }</title>

      <meta property="og:site_name" content={ baseTitle } />
      <meta property="og:url" content={ url } />
      <meta property="og:image" content={ !! p.openImage ? p.openImage.asset.url : null } />
      <meta property="og:title" content={ p.openTitle || title } />
      <meta property="og:description" content={ p.openGraphDescription || description  } />

      <meta name="twitter:site" content="@epsilonmgmt" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={ p.twitterTitle || title } />
      <meta name="twitter:image:src" content={ !! p.twitterImage ? p.twitterImage.asset.url : null } />
      <meta name="twitter:description" content={ p.twitterDescription || description } />
      <meta name="twitter:url" content={ url } />

    </Helmet>

  );


}
SEO.propTypes = propTypes;

export default SEO;