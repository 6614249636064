import each from 'lodash/each';
import PropTypes from 'prop-types';
import React from 'react';
import SanityBlockContent from '@sanity/block-content-to-react';

/**
 * Block Content.
 *
 * @param {object}    placeholders. Optional.
 */
const BlockContent = ( { className, placeholders, ...p } ) => {

  console.log( { p } );

  // Basic placeholders.
  if( p.placeholders ) {

    let blockIndex = 0;
    for( let block of p.blocks ) {

      let childIndex = 0;
      for( let child of block.children ) {

        each( p.placeholders, ( replace, find ) => {
          child.text = child.text.replace( new RegExp( `${find}`, 'gi' ), replace );
        } );

        block.children[childIndex] = child;

        childIndex++;
      }

      p.blocks[blockIndex] = block;

      blockIndex++;

    }

  }

  const BlockRenderer = props => {

    const { style = 'normal' } = props.node;

    if( style === 'xs' || style == 'sm' || style === 'lg' || style === 'xl'  ) {

      return (
        <p className={ `p-${style}` }>
          { props.children }
        </p>
      )
    }

    // Fall back to default handling
    return BlockContent.defaultSerializers.types.block( props );

  }

  const FileLinkRenderer = props => {

    console.log( { props } );

    const {
      node: {
        linkText,
        file: {
          asset: {
            url
          }
        }
      }
    } = props;

    return(
      <p>
        <a href={ url }>{ linkText }</a>
      </p>
    )
  }

  return(

    <SanityBlockContent
    className={ className }
    serializers={ { types: { block: BlockRenderer, fileLink: FileLinkRenderer } } }
    { ...p }
    />

  )
}
BlockContent.defaultSerializers = SanityBlockContent.defaultSerializers;
export default BlockContent;